import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  SlotAvailability,
  SlotResource,
} from '@wix/ambassador-availability-calendar/types';
import { formatRfcTimeStringToTimeSlotView } from '../../../../utils/dateAndTime/dateAndTime';
import {
  getServicePriceText,
  isOfferedAsOneTime,
} from '../../../../utils/payment/payment';
import { getSlotDuration } from '../../../../utils/duration/duration';
import { getLocationText } from '../../../../utils/bookingPreferences/bookingPreferences';
import { Optional } from '../../../../types/types';
import { ServicePayment } from '@wix/bookings-uou-types';
import { getFormattedPrice } from '../../../../utils/dynamicPricing/dynamicPricing';
import {
  isFullSlot,
  isSlotWithBookingsPolicyViolation,
  isTooEarlyToBookSlot,
  isTooLateToBookSlot,
} from '../../../../utils/slotAvailability/slotAvailability';
import {
  createCtaViewModel,
  CtaViewModel,
  memoizedCtaViewModel,
} from '../ctaViewModel/ctaViewModel';

export type AgendaSlot = {
  id: number;
  formattedTime: string;
  serviceName: string;
  spotsLeft?: string;
  duration?: string;
  location?: string;
  staffMember?: string;
  price?: string;
  policyViolation?: string;
  ctaViewModel?: CtaViewModel;
};

export type AgendaSlotsViewModel = AgendaSlot[];
export type EnrichedSlotAvailability = SlotAvailability & { id: number };

type AgendaSlotsViewModelParams = ViewModelFactoryParams<
  CalendarState,
  CalendarContext
> & {
  slots: EnrichedSlotAvailability[];
};

export const enrichSlotAvailability = (
  slotsAvailability?: SlotAvailability[],
) => {
  return (
    slotsAvailability?.map((slotAvailability, index) => ({
      ...slotAvailability,
      id: index,
    })) || []
  );
};

export const memoizedAgendaSlotsViewModel: MemoizedViewModalFactory<AgendaSlotsViewModel> =
  {
    dependencies: {
      settings: [
        'spotsLeftFormat',
        'spotsLeftVisibility',
        'slotDurationVisibility',
        'slotLocationVisibility',
        'slotPriceVisibility',
        'slotRegistrationStatusVisibility',
        'slotStaffMemberVisibility',
        'toEarlyToBookIndication',
        'toLateToBookIndication',
        'ctaVisibility',
        'noSpotsLeft',
      ],
      state: ['availableServices'],
      subDependencies: [memoizedCtaViewModel.dependencies],
    },
  };

export function createAgendaSlotsViewModel({
  state,
  context,
  slots,
}: AgendaSlotsViewModelParams): AgendaSlotsViewModel {
  const { availableServices } = state;
  const { t, settingsParams, settings, businessInfo } = context;
  const dateRegionalSettingsLocale = businessInfo!.dateRegionalSettingsLocale!;

  const isLocationVisible = settings.get(settingsParams.slotLocationVisibility);
  const isDurationVisible = settings.get(settingsParams.slotDurationVisibility);
  const isPriceVisible = settings.get(settingsParams.slotPriceVisibility);
  const isCtaVisible = settings.get(settingsParams.ctaVisibility);
  const isStaffMemberVisible = settings.get(
    settingsParams.slotStaffMemberVisibility,
  );
  const isPolicyViolationVisible = settings.get(
    settingsParams.slotRegistrationStatusVisibility,
  );

  return (
    slots?.map<AgendaSlot>((enrichedSlot) => {
      const slot = enrichedSlot.slot!;
      const service = availableServices.find(
        (service) => service.id === slot.serviceId,
      );

      const rfcStartTime = slot.startDate!;
      const rfcEndTime = slot.endDate!;

      const formattedTime = formatRfcTimeStringToTimeSlotView(
        rfcStartTime,
        dateRegionalSettingsLocale,
      );
      const serviceName = service?.info.name!;

      const location = isLocationVisible
        ? getLocationText(slot.location, t)
        : undefined;

      const staffMember = slot.resource!;
      const staffMemberName = isStaffMemberVisible
        ? staffMember.name
        : undefined;

      const duration = isDurationVisible
        ? context.wixSdkAdapter.isEditorMode()
          ? t('dummy-content.service.duration')
          : getSlotDuration({
              dateRegionalSettingsLocale,
              rfcStartTime,
              rfcEndTime,
              t,
            }).durationText
        : undefined;

      const price = isPriceVisible
        ? getSlotPrice({
            payment: service!.payment,
            staffMember,
            context,
            state,
          })
        : undefined;

      const spotsLeft = getSpotsLeft({ slot: enrichedSlot, context });

      const policyViolation = isPolicyViolationVisible
        ? getPolicyViolationText({ context, slot: enrichedSlot })
        : undefined;

      const ctaViewModel = isCtaVisible
        ? createCtaViewModel({ state, context, slots: [enrichedSlot] })
        : undefined;

      return {
        id: enrichedSlot.id,
        formattedTime,
        serviceName,
        location,
        spotsLeft,
        duration,
        price,
        staffMember: staffMemberName,
        ctaViewModel,
        policyViolation,
      };
    }) || []
  );
}

const getSpotsLeft = ({
  slot,
  context,
}: {
  slot: EnrichedSlotAvailability;
  context: CalendarContext;
}): AgendaSlot['spotsLeft'] => {
  const { t, settingsParams, settings, getContent } = context;

  const openSpots = slot.openSpots!;
  const isSpotsLeftVisible = settings.get(settingsParams.spotsLeftVisibility);

  if (!isSpotsLeftVisible || isSlotWithBookingsPolicyViolation(slot)) {
    return;
  }

  if (isFullSlot(slot)) {
    return getContent({
      settingsParam: settingsParams.noSpotsLeft,
      translationKey: 'app.settings.defaults.slots.no-spots-left',
    });
  }

  const customFormat = settings.get(settingsParams.spotsLeftFormat);
  if (customFormat) {
    return t('app.agenda-slot.spots-left.custom-label', {
      openSpots,
      spotsLeftFormat: customFormat,
    });
  }

  return t('app.agenda-slot.spots-left.label', {
    openSpots,
  });
};

const getSlotPrice = ({
  payment,
  staffMember,
  context,
  state,
}: {
  payment: ServicePayment;
  staffMember: SlotResource;
  context: CalendarContext;
  state: CalendarState;
}): Optional<string> => {
  const regionalSettingsLocale = context.businessInfo!.regionalSettingsLocale!;

  if (isOfferedAsOneTime(payment)) {
    if (payment.paymentDetails.isVariedPricing) {
      return getFormattedPrice({
        state,
        context,
        payment,
        choiceId: staffMember.id!,
        optionId: state.serviceVariants?.options?.[0].id!,
      });
    }

    return getServicePriceText(payment, regionalSettingsLocale);
  }
};

const getPolicyViolationText = ({
  context,
  slot,
}: {
  context: CalendarContext;
  slot: EnrichedSlotAvailability;
}): string | undefined => {
  const { getContent, settingsParams } = context;

  if (isTooEarlyToBookSlot(slot)) {
    return getContent({
      settingsParam: settingsParams.toEarlyToBookIndication,
      translationKey: 'app.settings.defaults.policy.to-early-to-book',
    });
  }

  if (isTooLateToBookSlot(slot)) {
    return getContent({
      settingsParam: settingsParams.toLateToBookIndication,
      translationKey: 'app.settings.defaults.policy.to-late-to-book',
    });
  }
};
